import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'))
const ConfirmAccount = React.lazy(() => import('./views/pages/confirmAccount/ConfirmAccount'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgetPassword = React.lazy(() => import('./views/pages/ForgetPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'))
const Logout = React.lazy(() => import('./views/pages/logout'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/auth/register" name="Register Account" element={<Register />} />
            <Route
              exact
              path="/auth/confirm-account"
              name="Confirm Account"
              element={<ConfirmAccount />}
            />
            <Route exact path="/auth/login" name="Login" element={<Login />} />
            <Route
              exact
              path="/auth/forget-password"
              name="Forget Password"
              element={<ForgetPassword />}
            />
            <Route
              exact
              path="/auth/reset-password"
              name="Reset Password"
              element={<ResetPassword />}
            />
            <Route exact path="/auth/logout" name="Logout" element={<Logout />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
